.header-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $navbarHeight;
  background-color: $white;
  z-index: 11;
  border-bottom: 1px solid $gray-300;
  min-width: 1100px;

  .navbar--angledown {
    margin: 3px;
  }

  .navbar--angledown {
    margin-top: 6px;
    margin-left: 8px;
  }

  .navbar--container {
    height: inherit;
  }

  .navbar--content-columns {
    grid-column-start: content;
    grid-column-end: content;
  }

  .navbar--logo {
    display: flex;
    overflow: hidden;

    img {
      margin: 9px 9px 9px 0px;
      height: $navbarHeight - (9 * 2);
    }
  }

  .navbar-link-group {
    margin-right: 1rem;
  }

  .navbar--editor {
    height: inherit;
    display: grid;
    grid-template-columns: [logo] auto [back] auto [project] 1fr [controls] auto;
    padding-left: $spacer;

    > div,
    .navigation {
      display: flex;
      align-items: center;
    }

    .navigation {
      padding: 0 1rem;
      margin-right: $spacer;
      border-left: 1px solid $gray-300;
      border-right: 1px solid $gray-300;
      .button--small {
        cursor: pointer;
      }
    }

    .logo {
      margin-right: $spacer;
      > img {
        width: 32px;
      }
    }

    .project h4 {
      margin-top: 0;
      margin-bottom: 0;
    }

    .button--small {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 5px 6px 4px 7px;
      margin: 2px;
      font-size: 12px;
      height: 33px;
      border: 0px;
      background-color: $white;
      > svg {
        margin: 2px;
      }
      &.navbar-download-group {
        margin: 2px 1.5rem 2px 0.5rem;
        .download-group-arrow-icon {
          margin-left: 0.3rem;
        }
      }
    }

    .button {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 5px 6px 4px 7px;
      border-radius: 2px;
      background-color: $blue-400;
      margin: 2px;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.92px;
      height: 33px;
      > svg {
        margin: 2px;
      }
      &.grey300 {
        background-color: $blue-400;
      }
      &.grey200 {
        background-color: $blue-300;
        color: $blue-700;
      }
    }

    .version-icon {
      background-color: #ffffff;
      color: $blue-800;
      margin: 0 6px 0 0;
      border-radius: 3px;
      padding: 5px 12px;
      > svg {
        margin: 2px;
      }
    }
  }

  ul.user-menu {
    min-width: 200px;
    border: 1px solid $gray-300;
    text-align: right;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li a {
      display: block;
      padding: 18px 20px;
      background-color: #ffffff;
      border-bottom: solid 1px $gray-300;
    }
  }
}

.navbar--wrapper {
  height: 100px;

  .navbar--unsupported-browser {
    background-color: $red-500;
    width: 100%;
    position: fixed;
    top: 60px;
    color: $white;
    text-align: center;
    z-index: 1;
  }
}

.header-sub-navbar {
  left: 0;
  top: $navbarHeight;
  height: $subNavbarHeight;
  z-index: 11;
}

.sub-navbar-padding {
  padding-top: $subNavbarHeight;
}

.navbar-notification-icon-wrapper,
.navbar-user-icon-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 0.9rem;

  &.border {
    &--left {
      border-left: solid 1px $gray-300;
    }
  }
}
.navbar-user-icon-wrapper {
  padding: 0 0.9rem;
}

.navbar-notification-icon-wrapper {
  border-left: solid 1px $gray-300;
  width: $sidebarMenuWidth;
  justify-content: center;
}

#root {
  margin-top: $navbarHeight;

  .container {
    height: calc(100vh - #{$navbarHeight});
  }
}

.nav-btn-icon {
  padding-left: 0.2rem;
}

.gaby-tooltip {
  padding: 7.5px 12px;
  border-radius: 5px;
  background: $dark;
  color: white;
  font-size: 13px;
  &::before {
    content: '';
    position: absolute;
    width: 0px;
    border: 5px solid transparent;
    border-right: 5px solid $dark;
    border-left: 0;
    transform: translate(calc(100%), -50%);
  }
  &.arrow-right::before {
    right: 0;
    top: 50%;
    border-right: 0;
    border-left: 5px solid $dark;
  }
  &.arrow-left::before {
    border-right: 5px solid $dark;
    border-left: 0;
    top: 50%;
    left: -10px;
  }
  &.arrow-top::before {
    top: 0;
    left: 50%;
    position: absolute;
    display: block;
    border-width: 5px;
    border-style: solid;
    transform: translate(-30%, -100%);
    border-color: transparent transparent $dark transparent;
  }
}
