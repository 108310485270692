.styled-paragraph--add-paragraph {
  margin-left: 10px;
  padding: 12px;
  border-radius: 50%;
  background: #e0eff9;
  color: #547ba2;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
}
.styled-paragraph-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 16px;
  p {
    margin: 0;
    padding: 0;
    color: #1e2533;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    width: 80px;
    height: 20px;
  }
}

.no-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
}

.styled-paragraph-input-wrapper {
  display: flex;
  gap: 8px;
}

.styled-paragraph-setting-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.styled-paragraph-setting-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styled-paragraph-color-icon {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}
.settings-icon:hover {
  cursor: pointer;
}
.seperate-setting-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px 0px rgba(31, 41, 55, 0.04), 0px 4px 6px 0px rgba(31, 41, 55, 0.04);
}

.seperate-setting-inner {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.no-paragraph {
  display: flex;
  justify-content: center;
  align-items: center;
}
